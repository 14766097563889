@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'TeX-Gyre-Heroes';
	src: url('./assets/fonts/TeX-Gyre-Heros/texgyreheros-regular.otf');
}

@layer base {
	html {
		font-family: TeX-Gyre-Heroes, system-ui, sans-serif;
	}
}

@layer utilities {
	.scrollbar::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	.scrollbar::-webkit-scrollbar-track {
		@apply bg-transparent;
	}

	.scrollbar::-webkit-scrollbar-thumb {
		@apply bg-primary;
		@apply rounded-sm;
	}

	.scrollbar::-webkit-scrollbar-thumb:hover {
		@apply bg-base-content;
	}
	.scrollbar::-webkit-scrollbar-corner {
		@apply bg-base-content;
	}
}
